import { useState } from "react";
import { sendReq } from "../scripts/dataHandler";
import { motion } from "framer-motion";

const routeVariants = {
  initial: {
   // y: "100vh",
    opacity: "0%"
  },
  final: {
    //y: "0vh",
    opacity: "100%",
    transition: {
      type: "spring",
      mass: 0.4,
    },
  },
};

//const url = "localhost:5000";

function Bobobot() {
    let [msgType, setMsgType] = useState("msg");
    let [author, setAuthor] = useState("")
  let [msg, setMsg] = useState("");
  let [response, setResponse] = useState("")

  let respTextTimer;

  const sendMessage = () => {
    if (!author) {
      setResponse("Musíš zadat autora");
    } else if (!msg) {
      setResponse("Musíš zadat zprávu");
    } else {
    sendReq(author,msgType,msg)
    setMsg("")
    setAuthor("")
    setResponse("Zpráva poslána :3")
    }
    respTextTimer = setTimeout(() => {setResponse("")}, 7500)
  }

    return ( 
      <motion.div
      variants={routeVariants}
      initial="initial"
      animate="final"
  
      className="about component"
    >
    <div className="boboBot section centerAlign column">
    <h1>BoBoBot</h1>
    <h2>Imagine, if you will, bogo.</h2>
    <h3>Samota? Deprese? Těžké životní rozhodnutí? Těžkej pokondr?<br/>Never fear! BoBoBot is here!</h3>

    <div className="row" style={{marginBottom: "3vh"}}>
      <h3 style={{marginRight: "1vw"}}>Autor: </h3>
      <input type="text" value={author} onChange={(e) => {
          setAuthor(e.target.value);
        }}/>
    </div>

    <div className="row" style={{marginBottom: "3vh"}}>
      <h3 style={{marginRight: "1vw"}}>Typ zprávy: </h3>
      <select
        id="msgType"
        onChange={(e) => {
          setMsgType(e.target.value);
        }}
      >
        <option value="msg">Zpráva</option>
        <option value="q">Otázka</option>
      </select>
    </div>

    <div className="row" style={{marginBottom: "3vh"}}>
      <h3 style={{marginRight: "1vw"}}>Zpráva: </h3>
      <textarea value={msg} rows="4" cols="50"
        onChange={(e) => {
          setMsg(e.target.value);
        }}
      />
    </div>

    <button onClick={sendMessage}>Odeslat</button>

    <h2 style={{position: "absolute", bottom: "2vh"}}>{response}</h2>
  </div></motion.div>  );
}

export default Bobobot;