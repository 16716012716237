const url = "https://bobobot-415616.ey.r.appspot.com/";
//const url = "http://localhost:8080/"


export const sendReq = async (author,type,msg) => {
  fetch(url + "req", {
    method: "POST",
    body: JSON.stringify({
      author: author,
      type: type,
      msg: msg
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      console.log(json.msg)
    });
};
