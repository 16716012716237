import { Link} from "react-router-dom";
import { motion } from "framer-motion";

const routeVariants = {
  initial: {
   // y: "100vh",
    opacity: "0%"
  },
  final: {
    //y: "0vh",
    opacity: "100%",
    transition: {
      type: "spring",
      mass: 0.4,
    },
  },
};

function Main() {
    return (  <motion.div
      variants={routeVariants}
      initial="initial"
      animate="final"
      className="home component"
    >
     <div className="headerBg"></div>
    <div className="section header centerAlign column">
      <h1>AHHOOOOOJJJ :3333</h1>
      <h2>
        my jsme co?
        <br /> NO PŘECE SKVĚLÝYY
      </h2>
      <Link to="/bobo">
      <button>BoBoBOt</button>
      </Link>
      </div>
      </motion.div> );
}

export default Main;