import Bobobot from "./pages/Bobobot";
import Main from "./pages/Main"
import "./styles/main.css";

import { BrowserRouter, Route, Routes} from "react-router-dom";
import { AnimatePresence } from "framer-motion";

function App() {

  return (
    <AnimatePresence>
    <BrowserRouter>
    <div className="container centerAlign column">
    <Routes>
          <Route path="/" element={<Main/>} />
          <Route path="/bobo" element={<Bobobot/>} />
        </Routes>
    </div>
    </BrowserRouter>
    </AnimatePresence>
  );
}

export default App;
